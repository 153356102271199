const toSentenceCase = (str) => {
  const lowerCase = str.toLowerCase();
  return `${lowerCase.charAt(0).toUpperCase()}${lowerCase.slice(1)}`;
};

const majorFinancialBenefitsList = [
  {
    benefit: "Rural teacher incentive",
    extraLine: "(less value of rental subsidy, where applicable)",
    extraValueLine: "per annum",
  },
  {
    benefit: "Rental subsidy",
    extraLine: "(deducted from rural teacher incentive, where applicable)",
  },
  {
    benefit: "Retention benefit",
    extraLine: "(up to 10 years)",
    extraValueLine: "per annum",
  },
  {
    benefit: "Experienced teacher benefit",
    extraLine:
      "(12 months of continuous permanent service required, up to 5 years)",
    extraValueLine: "per annum",
  },
  {
    benefit: "Recruitment bonus",
    extraLine: "(if applicable)",
    extraValueLine: "one off payment",
  },
  {
    benefit: "Stamp duty relief",
    extraLine: "(if applicable)",
    extraValueLine: "one off payment",
  },
  {
    benefit: "Rural and remote relocation support payment",
    extraLine: "(if applicable)",
    extraValueLine: "one off payment",
  },
];

const calculateMajorFinancialBenefits = (data, formData) => {
  let total = 0;
  const calculatedBenefits = majorFinancialBenefitsList.map((item, index) => {
    let value = 0;
    let totalDependents = 0;
    if (data.isIncentive) {
      switch (item.benefit) {
        case "Rural teacher incentive":
          switch (data.transferPoints) {
            case 4:
              value = data.connectedCommunitySchool === "true" ? 20000 : 0;
              break;
            case 6:
              value = data.connectedCommunitySchool === "true" ? 25000 : 20000;
              break;
            case 8:
              value = data.connectedCommunitySchool === "true" ? 30000 : 25000;
              break;
            default:
              break;
          }
          break;
        case "Rental subsidy":
          switch (data.transferPoints) {
            case 4:
              value = 50;
              break;
            case 6:
              value = 70;
              break;
            case 8:
              value = 90;
              break;
            default:
              break;
          }
          break;
        case "Retention benefit":
          switch (data.transferPoints) {
            case 4:
              value =
                data.connectedCommunitySchool === "true" &&
                data.isRetentionBenefit
                  ? 5000
                  : 0;
              break;
            case 6:
              value = data.isRetentionBenefit ? 5000 : 0;
              break;
            case 8:
              value = data.isRetentionBenefit ? 5000 : 0;
              break;
            default:
              break;
          }
          break;
        case "Experienced teacher benefit":
          switch (data.transferPoints) {
            case 4:
              value = data.connectedCommunitySchool === "true" ? 10000 : 0;
              break;
            case 6:
              value = 10000;
              break;
            case 8:
              value = 10000;
              break;
            default:
              break;
          }
          break;
        case "Recruitment bonus":
          switch (data.transferPoints) {
            case 4:
              value = 0;
              break;
            case 6:
              value = 20000;
              break;
            case 8:
              value = 20000;
              break;
            default:
              break;
          }
          break;
        case "Stamp duty relief":
          value = 10000;
          break;
        case "Rural and remote relocation support payment":
          if (formData.isPartner === "true") totalDependents++;
          totalDependents += formData.dependents;
          if (totalDependents <= 3) value = 5000;
          else if (totalDependents > 3) value = 8000;
          break;
        default:
          break;
      }
    }
    if (index !== 1) {
      total += value;
    }
    return {
      ...item,
      isIncluded: value > 0,
      value: value,
    };
  });
  return { benefits: calculatedBenefits, total };
};

const professionalBenefitsList = [
  // {
  //   benefit: "10 week trial placement",
  //   extraLine: "(prior to permanent appointment)",
  // },
  {
    benefit: "Additional personal leave days",
  },
  {
    benefit: "Additional professional development days",
  },
  {
    benefit: "Priority transfer",
    extraLine: "(years of service required)",
  },
  {
    benefit: "Transferred Officers Compensation",
    extraLine: "(years of service required)",
  },
];

const calculateProfessionalBenefits = (data) => {
  const calculatedBenefits = professionalBenefitsList.map((item) => {
    let value = "No";
    if (data.isIncentive) {
      switch (item.benefit) {
        case "10 week trial placement":
          switch (data.transferPoints) {
            case 4:
              value = "Yes";
              break;
            case 6:
              value = "Yes";
              break;
            case 8:
              value = "Yes";
              break;
            default:
              break;
          }
          break;
        case "Additional personal leave days":
          switch (data.transferPoints) {
            case 4:
              value = "2 days";
              break;
            case 6:
              value = "3 days";
              break;
            case 8:
              value = "4-5 days";
              break;
            default:
              break;
          }
          break;
        case "Additional professional development days":
          switch (data.transferPoints) {
            case 4:
              value =
                data.connectedCommunitySchool === "true" ? "1 day" : "1 day";
              break;
            case 6:
              value = "4 days";
              break;
            case 8:
              value = "4 days";
              break;
            default:
              break;
          }
          break;
        case "Priority transfer":
          switch (data.transferPoints) {
            case 4:
              value =
                data.connectedCommunitySchool === "true"
                  ? "5 years"
                  : "5 years";
              break;
            case 6:
              value = "3 years";
              break;
            case 8:
              value = "2-3 years";
              break;
            default:
              break;
          }
          break;
        case "Transferred Officers Compensation":
          switch (data.transferPoints) {
            case 4:
              value =
                data.connectedCommunitySchool === "true"
                  ? "5 years"
                  : "5 years";
              break;
            case 6:
              value = "3 years";
              break;
            case 8:
              value = "2-3 years";
              break;
            default:
              break;
          }
          break;
      }
    }
    return {
      ...item,
      isIncluded: value !== "No",
      value: value,
    };
  });
  return { benefits: calculatedBenefits };
};

const tieredMisoAllowances = [
  "Principals Expense",
  "Teachers of students with disabilities",
  "Home School Liaison",
];

const calculateAllowances = (data, formdata) => {
  const benefits = data.benefits;
  const misoAllowances = data.misoAllowances;
  const highest: {
    allowanceCode: number;
    allowanceDescription: string;
    value: number;
  }[] = [];
  for (const item of misoAllowances) {
    const found = highest.find((foundItem) => {
      if (foundItem)
        return (
          foundItem.allowanceCode === item.allowanceCode ||
          tieredMisoAllowances.some(
            (item) => item === foundItem.allowanceDescription
          )
        );
      else return false;
    });
    if (found) {
      if (found.value < item.value) {
        const index = highest.indexOf(found);
        highest[index].value = item.value;
      }
    } else
      highest.push({
        allowanceCode: item.allowanceCode,
        allowanceDescription: item.allowanceDescription,
        value: item.value,
      });
  }
  const totalMisoAllowances = highest.reduce(
    (acc, current) => acc + current.value,
    0
  );
  const otherAllowances = data.otherAllowances ?? null;
  const totalOtherAllowances = otherAllowances
    ? otherAllowances.reduce((acc, current) => acc + current.value, 0)
    : 0;

  const conditionalAllowances = data.conditionalAllowances ?? [];
  const cleanedConditionalAllowances = conditionalAllowances.map((item) => {
    if (item.spouse === "false") {
      return { ...item, dependentType: "None" };
    } else if (item.dependentType) {
      return { ...item, spouse: "true" };
    } else return item;
  });

  const filteredConditionalAllowances = cleanedConditionalAllowances.filter(
    (item) => {
      if (typeof Number(item.childrenCount) === "number") {
        if (item.spouse === "true") {
          return (
            Number(item.childrenCount) === formdata.dependents &&
            item.spouse === formdata.isPartner &&
            item.dependentType.toLowerCase() ===
              formdata.partnerType.toLowerCase()
          );
        } else if (item.spouse === "false") {
          return (
            Number(item.childrenCount) === formdata.dependents &&
            item.spouse === formdata.isPartner
          );
        }
      } else if (typeof item.childrenCount === "undefined") {
        if (item.spouse === "true") {
          return (
            item.spouse === formdata.isPartner &&
            item.dependentType.toLowerCase() ===
              formdata.partnerType.toLowerCase()
          );
        } else if (item.spouse === "false") {
          return item.spouse === formdata.isPartner;
        }
      }
    }
  );
  const totalConditionalAllowances = filteredConditionalAllowances
    ? filteredConditionalAllowances.reduce(
        (acc, current) => acc + current.value,
        0
      )
    : 0;
  return {
    otherBenefits: {
      benefits: benefits
        ? benefits.map((item) => {
            let valueResult;
            switch (typeof item.value) {
              case "number":
                valueResult = item.value;
                break;
              case "string":
                valueResult = toSentenceCase(item.value);
                break;
              default:
                break;
            }
            switch (item.benefitName) {
              case "TEPS benefit for each 50 days of casual/temporary service":
                valueResult = `${item.value} months`;
                break;
              default:
                break;
            }
            return {
              benefit: item.benefitName,
              value: valueResult,
            };
          })
        : [],
      total: 0,
    },
    misoAllowances: {
      benefits: misoAllowances
        ? misoAllowances.map((item) => {
            let extraValueLineResult = "per annum";
            let allowanceDescription: string;
            switch (item.allowanceDescription) {
              case "Demonstration lesson (per 30 min)":
                extraValueLineResult = "per 30 minutes";
                allowanceDescription = "Demonstration lesson";
                break;
              case "Demonstration lesson (per 40 min)":
                extraValueLineResult = "per 40 minutes";
                allowanceDescription = "Demonstration lesson";
                break;
              case "Aboriginal Student Liaison Off":
                allowanceDescription = "Aboriginal Student Liaison Officer";
                break;
              case "Supervisor Of Girls Up  To 200":
                allowanceDescription = toSentenceCase(
                  "Supervisor Of Girls Up To 200"
                );
                break;
              case "Supervisor Of Girls 201 To 400":
                allowanceDescription = toSentenceCase(
                  "Supervisor Of Girls 201 To 400"
                );
                break;
              case "Supervisor Of Girls 401 To 500":
                allowanceDescription = toSentenceCase(
                  "Supervisor Of Girls 401 To 500"
                );
                break;
              default:
                allowanceDescription = item.allowanceDescription;
                break;
            }
            return {
              benefit: allowanceDescription,
              value: item.value,
              extraValueLine: extraValueLineResult,
            };
          })
        : [],
      total: totalMisoAllowances,
    },
    otherAllowances: {
      benefits: otherAllowances
        ? otherAllowances.map((item) => ({
            benefit: item.allowanceDescription,
            value: item.value,
            extraValueLine: "per annum",
          }))
        : [],
      total: totalOtherAllowances,
    },
    conditionalAllowances: {
      benefits: filteredConditionalAllowances
        ? filteredConditionalAllowances.map((item) => ({
            benefit: item.allowanceDescription,
            value: item.value,
            extraValueLine: "per annum",
          }))
        : [],
      total: totalConditionalAllowances,
    },
  };
};

export const calculateBenefitsAndAllowances = (schoolList, formData) => {
  return schoolList.map((item) => {
    const majorFinancialBenefits = calculateMajorFinancialBenefits(
      item,
      formData
    );
    const professionalBenefits = calculateProfessionalBenefits(item);
    const allAllowances = calculateAllowances(item, formData);
    let totalAllowances = 0;
    for (const key in allAllowances) {
      totalAllowances += allAllowances[key].total;
    }
    const totalBenefitsAndAllowances =
      majorFinancialBenefits.total + totalAllowances;
    const newItem = {
      ...item,
      majorFinancialBenefits,
      professionalBenefits,
      benefitsAndAllowances: {
        majorFinancialBenefits,
        professionalBenefits,
        ...allAllowances,
      },
      totalBenefitsAndAllowances,
    };
    delete newItem.benefits;
    delete newItem.misoAllowances;
    delete newItem.otherAllowances;
    delete newItem.conditionalAllowances;
    return newItem;
  });
};
