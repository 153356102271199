import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import MapView from "@/views/Home/index.vue";
import CompareView from "@/views/Comparison/index.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: MapView,
  },
  {
    path: "/comparison",
    name: "comparison",
    component: CompareView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

export default router;
