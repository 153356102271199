<template>
  <div>
    <v-card v-if="!isDontShowAgain">
      <Dialog
        :displayCloseBtn="true"
        :openDialog="showPopup"
        icon="info_outline"
        title="Disclaimer"
        width="550px"
        @close="handleDialogReturn"
      >
        <template #text>
          <div class="pl-6 pr-6 d-flex flex-column">
            <div>
              The benefits and allowances calculator requires entry of some
              personal information. This information is not retained or tracked.
            </div>
            <div class="pt-5">
              <span class="font-weight-bold">Please note</span>
              that the results provided by the calculator are indicative only.
            </div>
            <div class="pt-5 d-flex justify-end align-center">
              <span>
                <v-checkbox
                  @change="
                    () => {
                      $gtm.trackEvent({
                        event: 'dont_show_again_selected',
                        item_category: 'disclaimer',
                        action: 'checkbox_click',
                        label:
                          setDontShowAgain == true ? 'Checked' : 'Unchecked',
                      });
                    }
                  "
                  v-model="setDontShowAgain"
                  class="pr-5"
                  label="Don't show again"
              /></span>
              <span>
                <AdsButton primary @click="handleDialogReturn"
                  >Close</AdsButton
                ></span
              >
            </div>
          </div>
        </template>
      </Dialog>
    </v-card>
    <v-card v-if="showInfoWindow">
      <Dialog
        :displayCloseBtn="true"
        :fullscreen="mobile"
        :openDialog="showInfoWindow"
        maxWidth="1150px"
        title="School information"
        @close="showInfoWindow = false"
        :returnHandler="handleDialogReturn"
      >
        <template #text>
          <div class="mt-3 pa-3">
            <InfoWindow :contents="modalInfo" />
          </div>
        </template>
      </Dialog>
    </v-card>
    <div v-if="!mobile" class="d-flex flex-wrap">
      <div>
        <aside
          style="min-width: 475px"
          :style="{ width: expanded ? '850px' : '475px' }"
        >
          <SearchResults
            :contentWidth="expanded ? '850px' : '475px'"
            :results="results"
          />
        </aside>
      </div>
      <div class="grow">
        <div v-if="isUserSearch" class="expandLeft ml-8 mt-6">
          <v-btn style="width: 48px; height: 48px" @click="expandResults" icon
            ><v-icon v-if="!expanded" color="primary">chevron_right</v-icon
            ><v-icon v-if="expanded" color="primary"
              >chevron_left</v-icon
            ></v-btn
          >
        </div>
        <MapView @showModalInfo="showInfo" />
      </div>
    </div>
    <div v-else-if="mobile" style="width: 100vw">
      <div :style="`${mobile ? 'width: 100vw' : ''}`">
        <SearchResults :results="results" :tabs="mobile">
          <template #mobile>
            <MapView :mapHeight="getMapHeight" @showModalInfo="showInfo" />
          </template>
        </SearchResults>
      </div>
    </div>
  </div>
</template>

<script>
import { AdsButton, Dialog } from "@nswdoe/doe-ui-core";

import SearchResults from "@/components/SearchResults";
import { mapGetters } from "vuex";
import MapView from "@/components/googleMapLoader/MapInfo";
import InfoWindow from "@/components/infoWindow/InfoWindow";
export default {
  name: "App",
  title: "Benefits Calculator",
  components: {
    SearchResults,
    MapView,
    Dialog,
    AdsButton,
    InfoWindow,
  },
  data() {
    return {
      expanded: false,
      setDontShowAgain: false,
      modalInfo: null,
      showInfoWindow: false,
      scrollerHeight: 0,
      showPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      isUserSearch: "isUserSearch",
      results: "moduleResults/results",
      isOpenDialog: "isOpenDialog",
      isDontShowAgain: "isDontShowAgain",
      showMarkerClicked: "showInfo",
    }),
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    getMapHeight() {
      return this.scrollerHeight;
    },
  },
  created() {
    this.$nextTick(() => {
      this.showPopup = this.isOpenDialog;
    });
  },
  watch: {
    showMarkerClicked(markerObj) {
      this.showInfo(markerObj);
    },
    showPopup(value) {
      if (value == true) {
        this.$gtm.trackEvent({
          event: "disclaimer_popup_viewed",
          item_category: "popup",
          action: "popup_displayed",
        });
      }
    },
  },
  methods: {
    async handleDialogReturn() {
      this.$gtm.trackEvent({
        event: "disclaimer_popup_closed",
        item_category: "popup",
        action: "close_button_click",
        dont_show_again_status:
          this.setDontShowAgain == true ? "Checked" : "Not checked",
      });
      // result = action.name e.g. "Ok" or "Cancel"
      this.showPopup = false;
      await this.$store.dispatch("setDontShowAgain", this.setDontShowAgain);
      await this.$store.dispatch("setOpenDialog", false);
    },
    showInfo(markerInfo) {
      this.modalInfo = markerInfo.item;
      this.showInfoWindow = true;
    },
    checkForBanner() {
      return this.mobile && this.isUserSearch;
    },
    setScrollHeight(val) {
      this.scrollerHeight = val;
    },
    expandResults() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss">
// use pre-defined ADS scss classes for the whole project, note here the styles are non-scoped in App.vue
@import "../../scss/ads";
@import url("https://fonts.googleapis.com/css2?family=Public+Sans&display=swap");
// example of overriding classes in `ads.scss`
.theme--light {
  // override the pre-defined scss classes for <a> in ads.scss as per the design for this project
  /*  a:not(.v-btn, .v-tab) {
        color: $ads-blue-1;
  
        &:visited {
          color: $ads-blue-1;
        }
      }*/
}

.banner .container {
  max-width: 100%;
}

.appSubtitle-mobile {
  font-size: 16px;
  padding: 0 14px;
  margin-bottom: 0;
}

.appTitle-mobile {
  font-size: 25px;
  font-weight: 600;
  display: inline-block;
  padding: 0 12px;
  margin: 0;
  vertical-align: top;
}

.expandLeft {
  z-index: 1;
  position: absolute;
  width: 48px;
  height: 48px;
  padding: 0;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  /* 03 dp */
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
    0px 3px 3px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14);
}

.container {
  padding: 0;

  .container {
    padding: 0;
  }
}

.mobile {
  .container {
    padding: 12px 4px;
  }
}

.default-text {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
}

.label-text {
  font-size: 16px !important;
}

.title-text {
  font-size: 20px !important;
}

.font-bold {
  font-weight: bold !important;
}

.default-text {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
}

.mobile-text {
  font-size: 12px !important;
}

.label-text {
  font-size: 16px !important;
}

.mobile-label {
  font-size: 14px !important;
}
</style>
