<template>
  <v-card class="pt-0 d-flex" flat>
    <div
      v-if="isUserSearch"
      style="height: -webkit-calc(100vh - 118px); width: 100%"
      class="d-flex flex-column mt-0"
    >
      <div ref="fieldsElement">
        <v-menu v-model="menu" :close-on-content-click="false" :offset-y="true">
          <template #activator="{ on, attrs }">
            <v-row :class="mobile ? 'pa-2' : ''" no-gutters>
              <v-col class="pl-6 pr-2 py-4"
                ><AdsTextField
                  v-model="filters.searchValue"
                  placeholder="Search school or region"
                  prepend-inner-icon="search"
                  @blur="
                    () => {
                      updateFilters();
                      emitAnalytics('search', filters.searchValue);
                    }
                  "
                  label="Search school or region"
                  hide-details="auto"
              /></v-col>
              <v-col
                :class="contentWidth == '475px' ? 'col-3' : 'col-2'"
                class="text-right pr-6 pl-2 py-4"
                ><v-btn
                  class="detailsButton"
                  outlined
                  style="height: 56px; border: 1px solid #ccc"
                  v-on="on"
                  v-bind="attrs"
                  ><v-icon class="pr-1" style="border-right: 1px solid #ccc"
                    >person_outline</v-icon
                  >
                  <v-icon v-if="!menu" class="pl-1">arrow_drop_down</v-icon>
                  <v-icon v-else class="pl-1">arrow_drop_up</v-icon>
                </v-btn></v-col
              >
            </v-row>
          </template>
          <v-card class="pa-5 flex-column grow">
            <div class="d-flex justify-space-between pb-4">
              <div class="title">Employee details</div>
              <div class="mt-n4">
                <v-btn icon @click="closeMenu">
                  <v-icon>close</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="grow">
              <SearchFields
                :class="`pb-2 ${mobile ? 'pl-2 mt-2 pr-2' : 'ml-5 mt-2 mr-5'}`"
                @buttonClick="closeMenu"
              />
            </div>
          </v-card>
        </v-menu>
        <div v-if="isUserSearch">
          <div v-if="!mobile" class="d-flex flex-wrap px-5">
            <ChipFilter
              key="filter_1"
              v-model="benefitsModal"
              :items="typeBenefits"
              heading="Benefits"
              name="Benefits"
              type="checkbox"
              class="pa-1"
            />
            <ChipFilter
              key="filter_2"
              v-model="levelModal"
              :items="schoolLevel"
              heading="School level"
              name="School level"
              type="checkbox"
              @change="
                () => {
                  updateFilters('selectedLevel');
                }
              "
              class="pa-1"
            />
            <ChipFilter
              key="filter_3"
              v-model="sizeModal"
              :items="sizeSchool"
              heading="School size"
              name="School size"
              type="checkbox"
              @change="
                () => {
                  updateFilters('selectedSize');
                }
              "
              class="pa-1"
            />
            <ChipFilter
              key="filter_4"
              v-model="pointsModal"
              :items="typePoints"
              heading="Transfer points"
              name="Transfer points"
              offset-x
              style="z-index: unset"
              type="checkbox"
              @change="
                () => {
                  updateFilters('selectedPoints');
                }
              "
              class="pa-1"
            />
            <ChipFilter
              key="filter_5"
              v-model="allowancesModal"
              :items="typeAllowances"
              heading="Allowances"
              name="Allowances"
              offset-x
              style="z-index: unset"
              type="checkbox"
              @change="
                () => {
                  updateFilters('selectedAllowance');
                }
              "
              class="pa-1"
            />
            <ChipFilter
              key="filter_6"
              v-model="typeModal"
              :items="schoolType"
              heading="School type"
              name="School type"
              offset-x
              style="z-index: unset"
              type="checkbox"
              @change="
                () => {
                  updateFilters('selectedType');
                }
              "
              class="pa-1"
            />
          </div>
          <v-slide-group
            v-else-if="mobile"
            :show-arrows="false"
            class="pa-2 d-flex flex-wrap"
            hide-delimiters
          >
            <v-slide-item key="slide_1">
              <ChipFilter
                key="filter_1"
                v-model="benefitsModal"
                :items="typeBenefits"
                heading="Benefits"
                name="Benefits"
                type="checkbox"
                @change="
                  () => {
                    updateFilters('selectedBenefits');
                  }
                "
              />
            </v-slide-item>
            <v-slide-item key="slide_2">
              <ChipFilter
                key="filter_2"
                v-model="levelModal"
                :items="schoolLevel"
                heading="School type"
                name="School type"
                type="checkbox"
                @change="
                  () => {
                    updateFilters('selectedLevel');
                  }
                "
              />
            </v-slide-item>
            <v-slide-item key="slide_3">
              <ChipFilter
                key="filter_3"
                v-model="sizeModal"
                :items="sizeSchool"
                heading="School size"
                name="School size"
                type="checkbox"
                @change="
                  () => {
                    updateFilters('selectedSize');
                  }
                "
              />
            </v-slide-item>
            <v-slide-item key="slide_4">
              <ChipFilter
                key="filter_4"
                v-model="pointsModal"
                :items="typePoints"
                heading="Transfer points"
                name="Transfer points"
                offset-x
                style="z-index: unset"
                type="checkbox"
                @change="
                  () => {
                    updateFilters('selectedPoints');
                  }
                "
              />
            </v-slide-item>
            <v-slide-item key="slide_5">
              <ChipFilter
                key="filter_5"
                v-model="allowancesModal"
                :items="typeAllowances"
                heading="Allowances"
                name="Allowances"
                offset-x
                style="z-index: unset"
                type="checkbox"
                @change="
                  () => {
                    updateFilters('selectedAllowance');
                  }
                "
              />
            </v-slide-item>
            <v-slide-item key="slide_6">
              <ChipFilter
                key="filter_6"
                v-model="typeModal"
                :items="schoolType"
                heading="School Status"
                name="School Status"
                offset-x
                style="z-index: unset"
                type="checkbox"
                @change="
                  () => {
                    updateFilters('selectedType');
                  }
                "
              />
            </v-slide-item>
          </v-slide-group>
          <div
            v-if="!schoolResults || schoolResults.length === 0"
            :class="`${mobile ? 'pa-2' : 'pa-5'} pt-0 pb-0`"
          >
            <div class="pt-2 subtitle-1 text-center">
              0 results matching your search and filters
            </div>
          </div>
          <div v-if="schoolResults.length > 0" class="px-6 pt-6 pb-4">
            <div class="d-flex justify-space-between align-end">
              <div class="default-text font-weight-bold">
                {{ schoolResults.length }} total results
              </div>
              <div>
                <AdsButton
                  :disabled="numberOfCompare == 0"
                  primary
                  @click="showComparisonTableAction()"
                >
                  <div>Compare</div>
                  <div
                    style="
                      background: #fff;
                      padding: 5px;
                      margin-left: 5px;
                      color: #041e42;
                      border-radius: 50%;
                      min-width: 28px;
                      min-height: 25px;
                    "
                    v-if="numberOfCompare < 5"
                  >
                    {{ numberOfCompare }}
                  </div>
                  <div
                    style="
                      background: #fff;
                      padding: 5px;
                      margin-left: 5px;
                      color: #041e42;
                      border-top-right-radius: 1em;
                      border-bottom-right-radius: 1em;
                      border-top-left-radius: 1em;
                      border-bottom-left-radius: 1em;
                      min-width: 70px;
                      min-height: 25px;
                    "
                    v-else
                  >
                    {{ numberOfCompare }} (max)
                  </div>
                </AdsButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isUserSearch"
        :class="`${mobile ? 'pa-0' : 'pa-5'} pt-0 pb-0`"
        :style="`border-top: 1px solid #ccc ${mobile ? 'width: 100vw' : ''}`"
      >
        <VirtualScroller
          v-if="!tabs"
          :contentWidth="contentWidth"
          :height="scrollerHeight.toString()"
          :results="schoolResults"
        />
        <v-tabs
          v-else-if="tabs"
          ref="tabNavElement"
          v-model="tab"
          class="ma-0 pa-0"
          fixedTabs
          slider-color="red"
        >
          <v-tab
            v-for="(item, index) in NAV_ITEMS"
            :key="`tab${index}`"
            class="default-text label-text"
            @click="trackGTMevent(item.tab)"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item key="tab1">
            <VirtualScroller
              :height="tabBarHeight.toString()"
              :results="schoolResults"
            />
          </v-tab-item>
          <v-tab-item key="tab2">
            <slot name="mobile" />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <div
      v-if="!isUserSearch"
      class="pa-5 ma-0 grow flex-column"
      style="height: -webkit-calc(100vh - 118px); /* google, safari */"
    >
      <div class="title pb-3">Employee details</div>
      <SearchFields :showReset="false" />
    </div>
  </v-card>
</template>
<script>
import { AdsTextField, ChipFilter, AdsButton } from "@nswdoe/doe-ui-core";
import SearchFields from "./SearchFields.vue";
import { mapGetters } from "vuex";
import VirtualScroller from "@/components/SearchResults/VirtualScroller";
import { ref } from "vue";
import { useElementSize } from "@vueuse/core";
export default {
  name: "SearchResults",
  components: {
    AdsTextField,
    SearchFields,
    ChipFilter,
    VirtualScroller,
    AdsButton,
  },
  props: {
    contentWidth: {
      type: String,
      default: "475px",
    },
    results: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tabs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      tab: null,
      menu: false,
      scrollerHeight: 0,
      tabBarHeight: 0,
      clientHeight: document.documentElement.clientHeight,
      NAV_ITEMS: [{ tab: "LIST VIEW" }, { tab: "MAP VIEW" }],
      schoolLevel: [
        { id: "P", name: "Primary school" },
        { id: "S", name: "High school" },
        { id: "C", name: "Comprehensive school" },
      ],
      sizeSchool: [
        { id: 100, name: "Up to 100 enrolments" },
        { id: 200, name: "Up to 200 enrolments" },
        { id: 300, name: "Up to 300 enrolments" },
        { id: 400, name: "Up to 400 enrolments" },
        { id: 500, name: "Up to 500 enrolments" },
        { id: 501, name: "500+ enrolments" },
      ],
      typeWork: [
        { id: "1", name: "Casual" },
        {
          id: "2",
          name: "Permanent",
        },
        {
          id: "3",
          name: "Temporary",
        },
      ],
      benefitsModal: [],
      filters: {
        isIncentiveOnly: false,
        isConnectedCommunityOnly: false,
        selectedBenefits: [],
        selectedLevel: [],
        selectedSize: [],
        selectedWorkType: [],
        selectedPoints: [],
        selectedAllowance: [],
        selectedType: [],
        searchValue: "",
      },
      sizeModal: [],
      typeModal: [],
      allowancesModal: [],
      levelModal: [],
      pointsModal: [],
      searchModal: "",
      typePoints: [
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 4, name: "4" },
        { id: 6, name: "6" },
        { id: 8, name: "8" },
      ],
      typeBenefits: [
        {
          id: "Rural teacher incentive",
          name: "Rural teacher incentive",
        },
        { id: "Rental subsidy", name: "Rental subsidy" },
        { id: "Retention benefit", name: "Retention benefit" },
        {
          id: "Experienced teacher benefit",
          name: "Experienced teacher benefit",
        },
        { id: "Recruitment bonus", name: "Recruitment bonus" },
      ],
      typeAllowances: [
        {
          id: "Broken Hill Allowance",
          name: "Broken Hill Allowance",
        },
        {
          id: "Hot Climatic allowance",
          name: "Hot Climatic Allowance",
        },
        {
          id: "Cold Climatic allowance",
          name: "Cold Climatic Allowance",
        },
        {
          id: "Country Area Program",
          name: "Country area program",
        },
        {
          id: "Isolation from goods and service allowance",
          name: "Isolation from goods and service allowance",
        },
        {
          id: "Medical and Dental re-imbursement",
          name: "Medical and Dental re-imbursement",
        },
        {
          id: "Motor Vehicle Depreciation Allowance",
          name: "Motor Vehicle Depreciation Allowance",
        },
        {
          id: "Relocation Subsidy",
          name: "Relocation Subsidy",
        },
        {
          id: "Vacation travel expense (no of vacation journeys)",
          name: "Vacation travel expense",
        },
      ],
      schoolType: [
        {
          id: "Incentive school",
          name: "Incentive school",
        },
        {
          id: "Connected community school",
          name: "Connected community school",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isUserSearch: "isUserSearch",
      numberOfCompare: "numberOfCompare",
    }),
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    schoolResults() {
      let results = this.results;
      const filters = this.filters;
      let usedFilters = [];
      if (filters.searchValue) {
        const filterRegion = filters.searchValue.toLowerCase();
        usedFilters.push("Search_Region");
        results = results.filter(
          (result) =>
            result.address.toLowerCase().indexOf(filterRegion) != -1 ||
            result.schoolFullName.toLowerCase().includes(filterRegion) ||
            result.regionName.toLowerCase().includes(filterRegion)
        );
      }
      if (filters.selectedPoints.length) {
        usedFilters.push("Transfer_points");
        results = results.filter((result) =>
          filters.selectedPoints.includes(result.transferPoints)
        );
      }
      if (filters.selectedLevel.length) {
        usedFilters.push("School_level");
        results = results.filter((result) =>
          filters.selectedLevel.includes(result.schoolLevel)
        );
      }
      if (filters.selectedSize.length) {
        usedFilters.push("School_size");
        const sortedSize = filters.selectedSize.sort((a, b) => b - a)[0];
        if (sortedSize > 500) {
          results = results.filter((result) => result.studentNumber > 500);
        } else
          results = results.filter(
            (result) => result.studentNumber <= sortedSize
          );
      }
      if (filters.selectedAllowance.length) {
        usedFilters.push("Allowances");
        const lowerCaseSelectedAllowance = filters.selectedAllowance.map(
          (item) => item.toLowerCase()
        );
        results = results.filter((result) => {
          return (
            result.benefitsAndAllowances.conditionalAllowances.benefits.some(
              (item) =>
                lowerCaseSelectedAllowance.indexOf(
                  item.benefit.toLowerCase()
                ) != -1
            ) ||
            result.benefitsAndAllowances.otherBenefits.benefits.some(
              (item) =>
                lowerCaseSelectedAllowance.indexOf(
                  item.benefit.toLowerCase()
                ) != -1
            )
          );
        });
      }
      if (filters.selectedBenefits.length) {
        const lowerCaseSelectedBenefits = filters.selectedBenefits.map((item) =>
          item.toLowerCase()
        );
        results = results.filter((result) => {
          return result.benefitsAndAllowances.majorFinancialBenefits.benefits.some(
            (item) =>
              lowerCaseSelectedBenefits.indexOf(item.benefit.toLowerCase()) !=
                -1 && item.isIncluded
          );
        });
        usedFilters.push("Benefits");
      }
      if (filters.isIncentiveOnly) {
        results = results.filter((result) => result.isIncentive === true);
        usedFilters.push("School_type");
      }
      if (filters.isConnectedCommunityOnly) {
        usedFilters.push("School_type");
        results = results.filter(
          (result) => result.connectedCommunitySchool === "true"
        );
      }
      if (filters.selectedType.length) {
        usedFilters.push("School_type");
        const isIncentiveSchool = filters.selectedType.some(
          (item) => item.toLowerCase() === "incentive school"
        );
        const isConnectedCommunitySchool = filters.selectedType.some(
          (item) => item.toLowerCase() === "connected community school"
        );
        results = results.filter(
          (result) =>
            (result.isIncentive === true && isIncentiveSchool) ||
            (result.connectedCommunitySchool === "true" &&
              isConnectedCommunitySchool)
        );
      }
      this.$gtm.trackEvent({
        event: "school_list_viewed",
        item_category: "school",
        action: "list_displayed_after_filter",
        filters_used:
          usedFilters && usedFilters.length > 0
            ? [...new Set(usedFilters)].join(",")
            : "",
        virtualUrl:
          location.href +
          "showResults" +
          (usedFilters && usedFilters.length > 0
            ? "&filters=" + [...new Set(usedFilters)].join("-")
            : ""),
      });
      return results;
    },
  },
  created() {
    this.resetFilters();
  },
  watch: {
    benefitsModal(value, oldValue) {
      if (oldValue != null && value != oldValue) {
        this.filters.selectedBenefits = value;
        this.updateFilters();
        this.emitAnalytics("filter", "selectedBenefits");
      }
    },
    levelModal(value, oldValue) {
      if (value != oldValue) {
        this.filters.selectedLevel = value;
        this.updateFilters();
        this.emitAnalytics("filter", "selectedLevel");
      }
    },
    sizeModal(value, oldValue) {
      if (value != oldValue) {
        this.filters.selectedSize = value;
        this.updateFilters();
        this.emitAnalytics("filter", "selectedSize");
      }
    },
    pointsModal(value, oldValue) {
      if (value != oldValue) {
        this.filters.selectedPoints = value;
        this.updateFilters();
        this.emitAnalytics("filter", "selectedPoints");
      }
    },
    allowancesModal(value, oldValue) {
      if (value != oldValue) {
        this.filters.selectedAllowance = value;
        this.updateFilters();
        this.emitAnalytics("filter", "selectedAllowance");
      }
    },
    typeModal(value, oldValue) {
      if (value != oldValue) {
        this.filters.selectedType = value;
        this.updateFilters();
        this.emitAnalytics("filter", "selectedType");
      }
    },
    fieldsHeight(val) {
      this.$nextTick(() => {
        this.scrollerHeight =
          document.documentElement.clientHeight - this.getScrollerHeight(val);
      });
    },
    tabNavHeight(val) {
      if (!this.tabBarHeight) {
        this.tabBarHeight = document.documentElement.clientHeight + val;
        this.$emit("scrollHeight", this.tabBarHeight);
      }
    },
  },
  methods: {
    trackGTMevent(label) {
      this.$gtm.trackEvent({
        event: "mobile_view_switched",
        action: "view_toggle_click",
        item_category: "mobile_view",
        view_type: label,
      });
    },
    emitAnalytics(type, value) {
      let gtmOptions = {
        event: "",
        item_category: "",
        action: "",
        "target-properties": "",
      };

      switch (type) {
        case "filter":
          gtmOptions.event = "filter_selected";
          gtmOptions.item_category = "results_filter";
          gtmOptions.action = "dropdown_select";
          gtmOptions["target-properties"] = null;
          gtmOptions = {
            ...gtmOptions,
            ...{
              filter_type: this.getFilterName(value),
              "target-properties": this.filters[value].join(","),
            },
          };
          break;
        case "search":
          gtmOptions.event = "filter_selected";
          gtmOptions.item_category = "results_filter";
          gtmOptions.action = "textbox_value";
          gtmOptions["target-properties"] = "School or Region";
          gtmOptions.value = value;
          break;
      }
      this.$gtm.trackEvent(gtmOptions);
    },
    getFilterName(filterKey) {
      const filtersOptions = [
        { key: "selectedBenefits", value: "Benefits" },
        { key: "selectedLevel", value: "school_level" },
        { key: "selectedSize", value: "school_size" },
        { key: "selectedPoints", value: "transfer_points" },
        { key: "selectedAllowance", value: "allowances" },
        { key: "selectedType", value: "school_type" },
      ];
      return filtersOptions.find((option) => option.key == filterKey).value;
    },
    updateFilters() {
      this.$store.commit("SET_IS_LOADING", true);
      this.$store.dispatch("moduleResults/updateFilters", this.filters);
      this.$store.commit("SET_IS_LOADING", false);
    },
    resetFilters() {
      this.$store.dispatch("moduleResults/updateFilters", {
        isIncentiveOnly: false,
        isConnectedCommunityOnly: false,
        selectedBenefits: [],
        selectedLevel: [],
        selectedSize: [],
        selectedWorkType: [],
        selectedPoints: [],
        selectedAllowance: [],
        selectedType: [],
        searchValue: "",
      });
    },
    showComparisonTableAction() {
      let compareSchools = this.$store.state.compareList;
      compareSchools = compareSchools.map((school) => {
        return school.schoolFullName;
      });
      let gtmOptions = {
        event: "compare_now_clicked",
        item_category: "school",
        action: "compare_now_button_click",
        schools_to_compare: compareSchools.join(","),
        virtualUrl: location.href + "compare",
      };
      this.$gtm.trackEvent(gtmOptions);
      this.$router.push("/comparison");
    },
    closeMenu() {
      this.menu = false;
    },
    checkForBanner() {
      return this.mobile && this.isUserSearch;
    },
    getScrollerHeight(elHeight) {
      const bannerHeight = 150;
      const total = bannerHeight + elHeight;
      return total;
    },
  },
  emits: ["scrollHeight", "tabHeight"],
  setup() {
    const fieldsElement = ref(null);
    const tabNavElement = ref(null);
    let fieldsWidth = 0;
    let fieldsHeight = 0;
    let tabNavWidth = 0;
    let tabNavHeight = 0;
    const setFields = () => {
      const { width, height } = useElementSize(fieldsElement);
      fieldsWidth = width;
      fieldsHeight = height;
    };
    const setTabNav = () => {
      const { width, height } = useElementSize(tabNavElement);
      tabNavWidth = width;
      tabNavHeight = height;
    };
    setFields();
    setTabNav();
    return {
      fieldsElement,
      tabNavElement,
      fieldsWidth,
      fieldsHeight,
      tabNavWidth,
      tabNavHeight,
    };
  },
};
</script>
<style scoped>
.detailsButton {
  padding: 16px !important;
  min-width: 85px !important;
}
.default-text {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
}

.mobile-text {
  font-size: 13px !important;
}

.label-text {
  font-size: 16px !important;
}

.mobile-label {
  font-size: 14px !important;
}
</style>
