import Vue from "vue";
import Vuex from "vuex";
import moduleResults from "./modules/results";

import { School } from "@/types/school";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    isDontShowAgain: false,
    isOpenDialog: true,
    isLoading: false,
    isUserSearch: false,
    mapStore: {},
    showMarker: {
      show: false,
      index: 0,
    },
    showInfo: {
      pan: false,
      item: null,
    },
    google: null,
    showModal: { show: false, index: 0 },
    formValues: {
      teacherFor: null,
      isPartner: null,
      partnerType: null,
      dependents: null,
      isAppointedForIncentive: null,
    },
    compareList: [] as School[],
    showComparisonTable: false,
  },
  modules: {
    moduleResults,
  },
  getters: {
    getGoogle: (state) => state.google,
    showMarker: (state) => state.showMarker,
    showModal: (state) => state.showModal,
    showInfo: (state) => state.showInfo,
    isDontShowAgain: (state) => state.isDontShowAgain,
    isOpenDialog: (state) => state.isOpenDialog,
    isUserSearch: (state) => state.isUserSearch,
    formValues: (state) => state.formValues,
    mapStore: (state) => state.mapStore,
    numberOfCompare: (state) => state.compareList.length,
    showComparisonTable: (state) => state.showComparisonTable,
  },
  mutations: {
    SET_GOOGLE(state, google) {
      state.google = google;
    },
    SET_SHOW_INFO(state, item) {
      state.showInfo = { pan: item.pan, item: item.item };
    },
    SET_IS_DONT_SHOW_AGAIN(state, isDontShowAgain) {
      state.isDontShowAgain = isDontShowAgain;
    },
    SET_IS_OPEN_DIALOG(state, isOpenDialog) {
      state.isOpenDialog = isOpenDialog;
    },
    SET_IS_SEARCH(state, isSearch) {
      state.isUserSearch = isSearch;
    },
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_FORM(state, formValues) {
      state.formValues = formValues;
    },
    SET_SHOWMARKER(state, showMarker) {
      state.showMarker = showMarker;
    },
    SET_SHOWMODAL(state, showModal) {
      state.showModal = showModal;
    },
    SET_MAP(state, map) {
      state.mapStore = map;
    },
    ADD_TO_COMPARE(state, payload) {
      const existingItem = state.compareList.find(
        (item) => item.schoolCode === payload.schoolCode
      );
      if (!existingItem) {
        state.compareList.push(payload);
      }
    },
    REMOVE_FROM_COMPARE(state, payload) {
      const index = state.compareList.findIndex(
        (item) => item.schoolCode === payload.schoolCode
      );
      if (index !== -1) {
        state.compareList.splice(index, 1);
      }
    },
    SET_SHOW_COMPARISON_TABLE(state, showComparisonTable) {
      state.showComparisonTable = showComparisonTable;
    },
  },
  actions: {
    setGoogle({ commit }, val) {
      commit("SET_GOOGLE", val);
    },
    setOpenDialog({ commit }, val) {
      commit("SET_IS_OPEN_DIALOG", val);
    },
    setDontShowAgain({ commit }, val) {
      commit("SET_IS_DONT_SHOW_AGAIN", val);
    },
    setSearchStatus({ commit }, val) {
      commit("SET_IS_SEARCH", val);
    },
    searchFormInfo({ commit }, formValues) {
      commit("SET_IS_SEARCH", true);
      commit("SET_FORM", formValues);
    },
    resetForm({ commit }) {
      commit("SET_IS_SEARCH", false);

      commit("SET_IS_LOADING", false);
      commit("SET_FORM", {
        teacherFor: null,
        isPartner: null,
        partnerType: null,
        dependents: null,
        isAppointedForIncentive: null,
      });
      commit("SET_IS_OPEN_DIALOG", true);
    },
    showMarker({ commit }, markerInfo) {
      commit("SET_SHOWMARKER", markerInfo);
    },
    showModal({ commit }, markerInfo) {
      commit("SET_SHOWMODAL", markerInfo);
    },
    setShowInfo({ commit }, markerInfo) {
      commit("SET_SHOW_INFO", markerInfo);
    },
    setMap({ commit }, map) {
      commit("SET_MAP", map);
    },
    addToCompare({ commit }, data) {
      commit("ADD_TO_COMPARE", data);
    },
    removeFromCompare({ commit }, data) {
      commit("REMOVE_FROM_COMPARE", data);
    },
    setShowComparisonTable({ commit }, showComparisonTable) {
      commit("SET_SHOW_COMPARISON_TABLE", showComparisonTable);
    },
  },
});
