var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-column"},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"subtitle-1"},[_vm._v("Do you have a spouse or partner?")]),_c('v-radio-group',{attrs:{"rules":_vm.required,"required":""},on:{"change":() => {
          _vm.emitAnalytics('partner', _vm.formValues.isPartner);
        }},model:{value:(_vm.formValues.isPartner),callback:function ($$v) {_vm.$set(_vm.formValues, "isPartner", $$v)},expression:"formValues.isPartner"}},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('v-radio',{attrs:{"label":"Yes","value":"true"}})],1),_c('div',{staticClass:"pl-3 pb-1"},[_c('v-radio',{attrs:{"label":"No","value":"false"}})],1)])]),(_vm.formValues.isPartner === 'true')?_c('AdsSelect',{class:`pb-0`,style:(`width: ${_vm.mobile ? '90vw' : ''}`),attrs:{"items":[
        { text: 'Dependent', value: 'Dep' },
        { text: 'Non dependent', value: 'nonDep' },
        { text: 'NSW public sector teacher', value: 'Teacher' },
      ],"rules":_vm.required,"label":"Which applies to your spouse?","required":""},on:{"change":() => {
          _vm.emitAnalytics('spouseType', _vm.formValues.partnerType);
        }},model:{value:(_vm.formValues.partnerType),callback:function ($$v) {_vm.$set(_vm.formValues, "partnerType", $$v)},expression:"formValues.partnerType"}}):_vm._e(),_c('AdsSelect',{staticClass:"pb-0",attrs:{"items":[0, 1, 2, 3, 4, 5, 6, 7, '8+'],"rules":_vm.required,"label":"Dependents","required":""},on:{"change":() => {
          _vm.emitAnalytics('dependents', _vm.formValues.dependents);
        }},model:{value:(_vm.formValues.dependents),callback:function ($$v) {_vm.$set(_vm.formValues, "dependents", $$v)},expression:"formValues.dependents"}}),_c('div',{staticClass:"grow text-right"},[(_vm.showReset)?_c('AdsButton',{staticClass:"mr-2",attrs:{"tertiary":""},on:{"click":_vm.reset}},[_vm._v("Reset")]):_vm._e(),_c('AdsButton',{attrs:{"buttonText":_vm.isUserSearch ? 'Update results' : 'See results'},on:{"click":_vm.validate}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }