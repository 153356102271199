<template>
  <div class="flex-column">
    <v-form ref="form" v-model="valid">
      <div class="subtitle-1">Do you have a spouse or partner?</div>
      <v-radio-group
        v-model="formValues.isPartner"
        @change="
          () => {
            emitAnalytics('partner', formValues.isPartner);
          }
        "
        :rules="required"
        required
      >
        <div class="d-flex">
          <div><v-radio label="Yes" value="true" /></div>
          <div class="pl-3 pb-1"><v-radio label="No" value="false" /></div>
        </div>
      </v-radio-group>
      <AdsSelect
        v-if="formValues.isPartner === 'true'"
        v-model="formValues.partnerType"
        @change="
          () => {
            emitAnalytics('spouseType', formValues.partnerType);
          }
        "
        :class="`pb-0`"
        :items="[
          { text: 'Dependent', value: 'Dep' },
          { text: 'Non dependent', value: 'nonDep' },
          { text: 'NSW public sector teacher', value: 'Teacher' },
        ]"
        :rules="required"
        :style="`width: ${mobile ? '90vw' : ''}`"
        label="Which applies to your spouse?"
        required
      />
      <AdsSelect
        v-model="formValues.dependents"
        class="pb-0"
        @change="
          () => {
            emitAnalytics('dependents', formValues.dependents);
          }
        "
        :items="[0, 1, 2, 3, 4, 5, 6, 7, '8+']"
        :rules="required"
        label="Dependents"
        required
      />
      <div class="grow text-right">
        <AdsButton v-if="showReset" class="mr-2" tertiary @click="reset"
          >Reset</AdsButton
        >
        <AdsButton
          @click="validate"
          :buttonText="isUserSearch ? 'Update results' : 'See results'"
        ></AdsButton>
      </div>
    </v-form>
  </div>
</template>

<script>
import { AdsButton, AdsSelect } from "@nswdoe/doe-ui-core";
import { mapGetters } from "vuex";

export default {
  name: "SearchFields",
  components: {
    AdsSelect,
    AdsButton,
  },
  data() {
    return {
      itemsTeachFor: [
        "Less than 1 year",
        "1 Year",
        "2 Years",
        "3 Years",
        "4 Years",
        "5 Years or more",
      ],
      formValues: {
        isPartner: null,
        partnerType: "None",
        dependents: null,
      },
      valid: false,
      required: [(v) => !!v || v === 0 || "field is required"],
    };
  },
  computed: {
    ...mapGetters({
      formInfo: "formValues",
      isUserSearch: "isUserSearch",
    }),
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  props: {
    showReset: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  mounted() {
    this.formValues = this.$store.getters.formValues;
  },
  watch: {
    formInfo(val) {
      this.formValues = val;
    },
  },
  methods: {
    emitAnalytics(type, value) {
      let gtmOptions = {
        event: "",
        item_category: "",
        action: "",
        "target-properties": "",
      };
      switch (type) {
        case "partner":
          gtmOptions.event = "spouse_status";
          gtmOptions.item_category = "status";
          gtmOptions.action = "radio_button_click";
          gtmOptions["target-properties"] = value == "true" ? "Yes" : "No";
          break;
        case "spouseType":
          gtmOptions.event = "spouse_type";
          gtmOptions.item_category = "type";
          gtmOptions.action = "dropdown_select";
          gtmOptions["target-properties"] =
            value && value.toLowerCase() == "dep"
              ? "Dependent"
              : value && value.toLowerCase() == "nondep"
              ? "Non-dependent"
              : value && value.toLowerCase() == "teacher"
              ? "NSW Public Sector Teacher"
              : value;

          break;
        case "dependents":
          gtmOptions.event = "number_of_dependents";
          gtmOptions.item_category = "dependents_count";
          gtmOptions.action = "dropdown_select";
          gtmOptions["target-properties"] = value;
          break;
        case "results_button_yes":
          gtmOptions.event = "results_button";
          gtmOptions.item_category = "see_results";
          gtmOptions.action = "button_click";
          gtmOptions["target-properties"] = null;
          gtmOptions = {
            ...gtmOptions,
            ...{
              spouse_status: "Yes",
              spouse_type: "yes",
              dependents_count: value.dependents,
              virtualUrl: location.href + "showResults",
            },
          };
          break;
        case "results_button_no":
          gtmOptions.event = "results_button";
          gtmOptions.item_category = "see_results";
          gtmOptions.action = "button_click";
          gtmOptions["target-properties"] = null;
          gtmOptions = {
            ...gtmOptions,
            ...{
              spouse_status: "No",
              spouse_type: "no",
              dependents_count: value.dependents,
              virtualUrl: location.href + "showResults",
            },
          };
          break;
      }
      this.$gtm.trackEvent(gtmOptions);
    },
    async validate() {
      this.$refs.form.validate();
      if (this.valid === true) {
        this.$emit("buttonClick");
        await this.$store.dispatch(
          "moduleResults/fetchResults",
          this.formValues
        );
        await this.$store.dispatch("searchFormInfo", this.formValues);
        if (this.formValues.isPartner == "true") {
          this.emitAnalytics("results_button_yes", this.formValues);
        } else {
          this.emitAnalytics("results_button_no", this.formValues);
        }
      }
    },
    async reset() {
      this.$emit("buttonClick");
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      await this.$store.dispatch("moduleResults/resetResults");
      await this.$store.dispatch("resetForm");
    },
  },
};
</script>
