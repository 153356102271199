var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-virtual-scroll',{class:`${
    _vm.mobile ? 'mx-n0' : _vm.inFullScreen ? '' : 'mx-n5'
  } invisible-scrollbar`,style:(`scrollbar-width: none; `),attrs:{"height":(parseInt(_vm.height) - 5).toString() + 'px',"item-height":_vm.mobile ? '180px' : '180px',"items":_vm.schoolResults,"max-width":_vm.mobile ? '100vw' : _vm.contentWidth,"bench":"5","three-line":""},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-list-item',{key:`schoolCode_${item.schoolCode}`,staticClass:"pa-6 pt-5 flex-column",style:(`border-bottom: 1px solid #ccc; height: ${
        _vm.mobile ? '180px' : '180px'
      }`)},[_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"d-flex flex-column justify-space-between",staticStyle:{"height":"100%"}},[_c('div',[_c('div',{class:`${
                _vm.mobile ? 'mobile-label' : 'label-text'
              }  font-weight-bold`,staticStyle:{"color":"#002664","cursor":"pointer"},on:{"click":function($event){return _vm.showResult(item)}}},[_vm._v(" "+_vm._s(item.schoolFullName)+" ")]),(item.address)?_c('div',{class:`${_vm.mobile ? 'mobile-text' : 'default-text'}`,staticStyle:{"word-break":"break-word","color":"#22272b"}},[_vm._v(" "+_vm._s(item.address)+" ")]):_vm._e()]),_c('div',[(item.transferPoints)?_c('div',{class:`${_vm.mobile ? 'mobile-text' : 'default-text'} mt-2`,staticStyle:{"color":"#22272b"}},[_vm._v(" "+_vm._s(item.transferPoints)+" transfer points ")]):_vm._e(),(item.isIncentive)?_c('Chip',{staticClass:"pa-1",attrs:{"color":_vm.COLOURS.RENTAL_SUBSIDY.background,"dense":"","label":"","small":"","text":"Rental subsidy available","textColor":"primary"}},[_c('span',{class:`${
                  _vm.mobile ? 'mobile-text' : 'default-text pt-1'
                } ma-0 pa-0`,staticStyle:{"font-weight":"normal !important"}},[_vm._v("Rental subsidy available")])]):_vm._e()],1)]),_c('div',{staticClass:"text-right d-flex flex-column justify-space-between"},[_c('div',[(
                item.isIncentive || item.connectedCommunitySchool === 'true'
              )?_c('div',[(item.isIncentive)?_c('div',{staticClass:"mb-2"},[_c('Chip',{attrs:{"color":_vm.COLOURS.INCENTIVE.background,"textColor":_vm.COLOURS.INCENTIVE.text,"small":"","text":"INCENTIVE SCHOOL"}},[_c('span',{class:`${
                      _vm.mobile ? 'mobile-text' : 'default-text'
                    } font-weight-bold`},[_vm._v(" INCENTIVE SCHOOL ")])])],1):_vm._e(),(item.connectedCommunitySchool === 'true')?_c('div',{staticClass:"mb-2"},[_c('Chip',{attrs:{"color":_vm.COLOURS.CC.background,"textColor":_vm.COLOURS.CC.text,"small":"","text":"CC SCHOOL"}},[_c('span',{class:`${
                      _vm.mobile ? 'mobile-text' : 'default-text'
                    } font-weight-bold`},[_vm._v(" CC SCHOOL ")])])],1):_vm._e()]):_vm._e(),_c('div',[_c('div',{staticClass:"default-text",staticStyle:{"color":"#22272b"}},[_vm._v(" Total major benefits ")]),_c('div',{class:`${
                  _vm.mobile ? 'mobile-label' : 'label-text'
                }  font-weight-bold`},[_c('span',{staticClass:"body-2",staticStyle:{"color":"#22272b"}},[_vm._v("up to ")]),_c('span',{staticClass:"label-text"},[_vm._v(_vm._s(`$${item.benefitsAndAllowances.majorFinancialBenefits.total.toLocaleString()}`))])])])])])]),_c('div',{staticClass:"grow text-right",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"mt-0"},[(
              !_vm.compareList.find(
                (school) => school.schoolCode === item.schoolCode
              )
            )?_c('AdsButton',{staticClass:"subtitle-1",staticStyle:{"padding":"0"},attrs:{"icon":"add_circle_outline","tertiary":true,"disabled":_vm.numberOfCompare >= 5,"button-text":"Add to compare"},on:{"click":function($event){return _vm.addToCompare(item)}}}):_vm._e(),(
              _vm.compareList.find(
                (school) => school.schoolCode === item.schoolCode
              )
            )?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"default-text .body-2 pr-2 grow align-self-center",staticStyle:{"color":"#495054"}},[_vm._v("Added to compare")]),_c('AdsButton',{staticClass:"font-weight-bold pr-0 removeSchool subtitle-2 primary--text",staticStyle:{"padding":"0","padding-left":"2px"},attrs:{"tertiary":true,"button-text":"Remove"},on:{"click":function($event){return _vm.remoteFromCompare(item)}}})],1):_vm._e()],1)])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }